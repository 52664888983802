import React, {useEffect, useState} from "react";

import {
    Box,
    Button,
    Container,
    Divider,
    Flex,
    FormControl,
    HStack,
    Image,
    Input,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useToast,
    Link,
} from "@chakra-ui/react";
import {useTranslation} from 'react-i18next';
import axios from 'axios';
import {useRegisterMutation} from "./services/cmApi";
import logoPng from "./assets/logo.png";
import aiEvaluationPng from "./assets/img/ai_evaluation.png";
import fullScoreTemplatePng from "./assets/img/full_score_template.png";
import examTypesPng from "./assets/img/exam_types.png";
import {OAuthButtonGroup} from "./components/OAuthButtonGroup";
import {PasswordField} from "./components/PasswordField";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import AuthService from "./services/auth.service";
import {useHistory, useLocation} from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


export const Register = () => {
    // const [username, setUsername] = useState("");
    let history = useHistory();
    const [validCode, setValidCode] = useState("");
    const [invitationCode, setInvitationCode] = useState("");
    const query = useQuery();
    const {t, i18n} = useTranslation();
    const [referralCode, setReferralCode] = useState("");
    const [gid, setGid] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [registerRequest, isRegisterError] = useRegisterMutation()
    const toast = useToast();
    const location = useLocation();
    const {state} = location;
    const [defaultIdx, setDefaultIdx] = useState(parseInt(state?.defaultIdx || 0));
    const [email, setEmail] = useState(state?.email || "");
    const [phone, setPhone] = useState(state?.phone || "");

    useEffect(() => {
        const paramValue = query.get('r');
        if (paramValue) {
            setReferralCode(paramValue);
        }
        const gidParam = query.get('gid');
        if (gidParam) {
            setGid(gidParam);
        }
    }, []);  // <-- empty dependency array to run only once on mount
    //let code = new URLSearchParams(useLocation().search).get('_r');
    //setReferralCode(code);


    function createInputField(userType) {
        const [countdown, setCountdown] = useState(null);
        const [validCode, setValidCode] = useState("");
        const [password, setPassword] = useState("");
        // 发送验证码请求
        const sendVerificationCode = async (userType) => {
            if ((userType === "email" && !email) || (userType === "phone" && !phone)) {
                toast({
                    title: t('Username cannot be empty'),
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                });
                return
            }
            axios.post(API_URL + '/api/send_totp',
                userType === "email" ? {
                    'email': email,
                    'routeName': 'signup',
                } : {
                    'phone': phone,
                    "routeName": 'signup',
                }
            ).then(response => {
                    if (response.status === 200) {
                        console.log("The verification code has been sent successfully，")
                    }
                }
            ).catch(error => {
                console.log('Error sending verification code:', error);
                toast({
                    // title: t("error_registration_failed"),
                    description: error.response.data.error,
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                });
            })
            startCountdown();
        }

        const startCountdown = () => {
            let remainingTime = 60; // 假设倒计时为60秒
            const intervalId = setInterval(() => {
                setCountdown(remainingTime);
                remainingTime -= 1;
                if (remainingTime <= 0) {
                    clearInterval(intervalId);
                    setCountdown(-1);
                }
            }, 1000);
        };

        const handleRegister = async (userType) => {
            mixpanel.track('Register Button Click', {
                userType: userType
            });

            if (userType === "phone" && !isValidPhoneNumber(phone)) {
                toast({
                    title: t("error_bad_phone_format"),
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                });
                return;
            }
            if (userType === "email" && !isValidEmail(email)) {
                toast({
                    title: t("error_bad_email_format"),
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                });
                return;
            }
            if (password === "") {
                toast({
                    title: t("error_password_cannot_be_empty"),
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                });
                return;
            }
            if (validCode === "") {
                toast({
                    title: t("error_valid_cannot_be_empty"),
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                });
                return;
            }

            try {
                let currentUrl = new URL(window.location.href);
                let searchParams = new URLSearchParams(currentUrl.search);
                let username = userType === "email" ? email : phone;
                let formData = {
                    "username": username,
                    "full_name": "",
                    "country_code": "",
                    "password": password,
                    "referral_code": referralCode,
                    "gid": gid,
                    "totp_code": validCode
                };
                console.log("formData:", formData);
                if (userType === "email") {
                    formData["email"] = email;
                } else {
                    formData["phone_number"] = phone;
                }
                const response = await registerRequest(formData).unwrap();
                console.log("Register successful in");

                // 注册完成自动登录
                AuthService.login(email, phone, password).then(
                    () => {
                        window.location.href = '/toefl/speaking'
                    },
                    (error) => {
                        console.log(error);
                        window.location.href = '/login'
                    }
                );
                toast({
                    title: t("registration_success"),
                    // description: t("registration_go_verify_email"),
                    status: "success",
                    duration: 2500,
                    isClosable: true,
                });

            } catch (error) {
                console.log("error", error)
                toast({
                    // title: t("error_registration_failed"),
                    description: error.data.error,
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                });
            }
        };

        return (
            <Stack spacing="2">
                <FormControl>
                    {userType === "email" ? (
                        <Input
                            type="email"
                            name="email"
                            id="email"
                            placeholder={t("email")}
                            style={{
                                borderBottom: "1px solid #ccc",
                                borderLeft: "none",
                                borderRight: "none",
                                borderTop: "none",
                                display: "block",
                                width: "100%",
                                padding: "8px 0",
                                color: "#999",
                                fontSize: "16px",
                                outline: "none",
                                transition: "border-color 0.3s ease",
                                borderRadius: 0,
                            }}
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                        />
                    ) : (
                        <PhoneInput
                            name="phone"
                            id="phone"
                            international
                            country={'us'}
                            preferredCountries={['us', 'cn']}
                            onChange={(e) => {
                                console.log("+" + e)
                                setPhone("+" + e)
                            }}
                            buttonStyle={{
                                borderBottom: "1px solid #ccc",
                                borderLeft: "none",
                                borderRight: "none",
                                borderTop: "none",
                            }}
                            inputStyle={{
                                borderBottom: "1px solid #ccc",
                                borderLeft: "none",
                                borderRight: "none",
                                borderTop: "none",
                                display: "block",
                                width: "100%",
                                height: "40px",
                                color: "#999",
                                fontSize: "16px",
                                outline: "none",
                                transition: "border-color 0.3s ease",
                                borderRadius: 0,
                            }}
                            value={phone}
                        />
                    )}
                </FormControl>
                <PasswordField onChange={(e) => setPassword(e.target.value)}/>
                <FormControl>
                    <Flex>
                        <Input
                            type="text"
                            placeholder={t("Verification code")}
                            style={{
                                borderBottom: "1px solid #ccc",
                                borderLeft: "none",
                                borderRight: "none",
                                borderTop: "none",
                                display: "block",
                                width: "100%",
                                padding: "8px 0",
                                color: "#999",
                                fontSize: "16px",
                                outline: "none",
                                transition: "border-color 0.3s ease",
                                borderRadius: 0,
                            }}
                            onChange={(e) => setValidCode(e.target.value)}
                        />
                        <Button width={'150px'}
                                style={{backgroundColor: '#FFA500'}}
                                onClick={() => {
                                    sendVerificationCode(userType)
                                }}
                                disabled={countdown !== null && countdown >= 0}
                        >
                            <Text style={{
                                fontSize: "14px",
                                color: "#000",
                                fontWeight: "bold",
                                letterSpacing: "1px",
                            }}>{countdown == null ?
                                t("Send Code") :
                                countdown < 0 ?
                                    t('Resend') :
                                    t('Resend') + `(${countdown} s)`}
                            </Text>
                        </Button>
                    </Flex>
                </FormControl>
                <FormControl>
                    <Input
                        type="text"
                        placeholder={t("invitation_code")}
                        style={{
                            borderBottom: "1px solid #ccc",
                            borderLeft: "none",
                            borderRight: "none",
                            borderTop: "none",
                            display: "block",
                            width: "100%",
                            padding: "8px 0",
                            color: "#999",
                            fontSize: "16px",
                            outline: "none",
                            transition: "border-color 0.3s ease",
                            borderRadius: 0,
                        }}
                        onChange={(e) => setInvitationCode(e.target.value)}
                        value={invitationCode}
                    />
                </FormControl>
                <HStack justify="space-between">
                    <HStack spacing="1">
                        <Text
                            color="muted"
                            style={{
                                fontSize: "14px", // 减小字体大小
                                marginBottom: "0px", // 移除底部外边距
                            }}
                        >
                            {t("registration_already_registered")}?
                        </Text>
                        <Button
                            variant="link"
                            colorScheme="blue"
                            onClick={() => {
                                const location = {
                                    pathname: '/login',
                                    state: {defaultIdx, phone, email, password}
                                };
                                history.push(location);
                            }}
                            style={{
                                fontSize: "14px", // 减小字体大小
                            }}
                        >
                            {t("login_now")}>
                        </Button>
                    </HStack>
                    {/*<Button variant="link" colorScheme="blue" size="sm"*/}
                    {/*        onClick={() => {*/}
                    {/*            history.push('/reset_password_request')*/}
                    {/*        }}*/}
                    {/*>*/}
                    {/*    {t("forgot_password")}?*/}
                    {/*</Button>*/}
                </HStack>
                <Box height={'10px'}></Box>
                <Text fontSize="sm" color="gray.500" mt={2}>
                    By continuing, you agree to our <Link href="https://lingoleap.ai/terms-of-use/" isExternal color="blue.500">Terms of Use</Link> and <Link href="https://lingoleap.ai/read/privacy-policy/" isExternal color="blue.500">Privacy Policy</Link>.
                </Text>
                <Button 
                    colorScheme="blue"
                    onClick={() => handleRegister(userType)}
                    mt={2}
                >
                    {t("register")}
                </Button>
                <HStack>
                    <Divider/>
                    <Text textStyle="sm" whiteSpace="nowrap" color="fg.muted">
                        Or
                    </Text>
                    <Divider/>
                </HStack>
                <OAuthButtonGroup/>
            </Stack>
        )
    }


    function isValidEmail(emailValue) {
        const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        return emailRegex.test(emailValue);
    }

    function isValidPhoneNumber(phoneNumber) {
        const emailRegex = /^\+[0-9]*$/;
        return emailRegex.test(phoneNumber);
    }

    return (
        <Container maxWidth="2000px"
                   maxHeight="100%"
                   maxW="lg"
                   py={{base: '12', md: '24'}}
                   px={{base: '0', sm: '8'}}
                   minH="100vh"
                   display="flex"
                   justifyContent="center"
                   alignItems="center">
            <Stack>
                <Flex justifyContent="center" alignItems="center">
                    <Flex alignItems="center">
                        <Image className="circlex-logo" width={50} height={50} src={logoPng}/>
                        <Box width={'10px'}></Box>
                        <span className="ml-2"
                              style={{
                                  fontWeight: 'bold',
                                  letterSpacing: '1px',
                                  fontSize: '30px'
                              }}>LINGOLEAP {t('An AI English test preparation platform from Silicon Valley')}</span>
                    </Flex>
                </Flex>
                <Box height={"50px"}></Box>
                <Flex justifyContent="center" alignItems="center">
                    <Flex>
                        <Flex direction={'column'} style={{maxWidth: '580px', minWidth: '240px'}}>
                            <Text style={{
                                fontWeight: 'bold',
                                fontSize: '25px',
                                letterSpacing: '1px'
                            }}>{t("your personal test prep assistant")}</Text>
                            <Box height={'30px'}></Box>
                            <Flex>
                                <Flex alignItems="center">
                                    <Image width={25} height={25} src={fullScoreTemplatePng}/>
                                    <Box width={"20px"}></Box>
                                    <Text style={{
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                        letterSpacing: '1px'
                                    }}>{t("automatically generate a perfect score template")}</Text>
                                </Flex>
                            </Flex>
                            <Box height={'15px'}></Box>
                            <Flex>
                                <Flex alignItems="center">
                                    <Image width={25} height={25} src={aiEvaluationPng}/>
                                    <Box width={"20px"}></Box>
                                    <Text style={{
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                        letterSpacing: '1px'
                                    }}>{t("AI automatically corrects and gives guidance and suggestions")}</Text>
                                </Flex>
                            </Flex>
                            <Box height={'15px'}></Box>
                            <Flex>
                                <Flex alignItems="center">
                                    <Image width={25} height={25} src={examTypesPng}/>
                                    <Box width={"20px"}></Box>
                                    <Text style={{
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                        letterSpacing: '1px'
                                    }}>{t("TOEFL IELTS all round support")}</Text>
                                </Flex>
                            </Flex>
                            <Box height={'120px'}></Box>
                        </Flex>
                    </Flex>
                    <Box width={"80px"}></Box>
                    <Box
                        py={{base: '0', sm: '8'}}
                        px={{base: '4', sm: '10'}}
                        bg={{base: 'transparent', sm: 'bg-surface'}}
                        boxShadow="0 2px 12px 0 rgb(0 0 0 / 16%)"
                        borderRadius={{base: 'none', sm: 'xl'}}
                        width={"400px"}
                    >
                        <Tabs
                            isFitted variant='enclosed'
                            defaultIndex={defaultIdx}
                            onChange={(index) => {
                                setDefaultIdx(index);
                            }}>
                            <TabList mb='1em'>
                                <Tab>{t("email")}</Tab>
                                <Tab>{t("Phone Number")}</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel sx={{padding: 0}}>
                                    <div>
                                        {createInputField("email")}
                                    </div>
                                </TabPanel>
                                <TabPanel sx={{padding: 0}}>
                                    <div>
                                        {createInputField('phone')}
                                    </div>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Box>
                </Flex>
                <Box height={"100px"}></Box>
            </Stack>
        </Container>
    );
};

export default Register;
