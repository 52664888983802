import React, {useEffect, useState} from "react";
import {Badge, Box, Center, Container, Flex, Icon, Spinner, Stack, Text, useColorModeValue} from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import {selectGrading} from "../redux/shareSlice";
import {useGetGradingQuery, useUpdateSharingStatusMutation} from "../services/cmApi";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import {CheckIcon} from "@chakra-ui/icons";
import ReactAudioPlayer from "react-audio-player";
import {ShareResultButton} from "./ShareResultButton";
import ResponseFeedBack from "./ResponseFeedBack";
import {useTranslation} from 'react-i18next';
import {selectToeflData, updateToelfGid} from "../redux/toeflSlice";
import "assets/css/Markdown.css"
import ChakraMarkdownComponents from "./ChakraMarkdownComponents";
import ConversationTable from "./ConversationTable";
import {IeltsSpeakingQuestionAnswerList} from "./IeltsSpeakingQuestionAnswerList";
import {ViewAppResultPanel} from "./ViewAppResultPanel"

const SectionCard = (props) => {
    const {children} = props

    return <Box>{children}</Box>
}

const SectionCardContent = (props) => {
    const {children} = props
    return (
        <Stack
            bg={useColorModeValue('white', 'gray.800')}
            boxShadow="0 2px 12px 0 rgb(0 0 0 / 16%)"
            p={4}
            borderRadius={20}
            align={'center'}
            pos={'relative'}
            _after={{
                w: 0,
                h: 0,
                borderLeft: 'solid transparent',
                borderLeftWidth: 16,
                borderRight: 'solid transparent',
                borderRightWidth: 16,
                borderTop: 'solid',
                borderTopWidth: 16,
                borderTopColor: useColorModeValue('white', 'gray.800'),
                pos: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
            }}>
            {children}
        </Stack>
    )
}

const SectionCardHeading = (props) => {
    const {children} = props

    return (
        <Text as={'h3'} fontSize={'md'}>
            {children}
        </Text>
    )
}

const SectionCardText = (props) => {
    const {children} = props
    return (
        <Text
            textAlign={'justify'}
            whiteSpace='pre-line'
            width="full"
            color={useColorModeValue('gray.600', 'gray.400')}
            fontSize={'sm'}>
            {children}
        </Text>
    )
}

export function ViewResultPanel(props) {
    const {t, i18n} = useTranslation();

    const [linkCopied, setLinkCopied] = useState(false);
    const grading = useSelector(selectGrading);
    const toeflData = useSelector(selectToeflData);
    const gid = props.gid || toeflData.gid;
    const dispatch = useDispatch();
    let grading_result = useGetGradingQuery({gid: gid});
    const isOwner = (grading_result.status === 'fulfilled' && grading_result.data.data['is_owner']);
    const isJsonDataBool = (grading_result.status === 'fulfilled' && grading_result.data.data['result'] && isJsonData(grading_result.data.data['result']))
    useEffect(() => {
        dispatch(updateToelfGid(gid));
    }, [gid])
    const [updateSharingStatusRequest, isUpdateSharingStatusError] = useUpdateSharingStatusMutation();

    function isJsonData(result) {
        try {
            const jsonStrings = result.split('\n').filter(Boolean);
            const parsedData = jsonStrings.map(jsonStr => {
                try {
                    const parsedObj = JSON.parse(jsonStr);
                    return (typeof parsedObj === 'object' && parsedObj !== null) ? parsedObj : null;
                } catch (parseError) {
                    console.error('Error parsing JSON string:', parseError);
                    return null;
                }
            }).filter(Boolean);
            return parsedData.length !== 0;
        } catch (error) {
            return false;
        }
    }

    const getField = (fieldName) => {
        if (grading_result.status === 'fulfilled') {
            return grading_result.data.data[fieldName];
        } else {
            return "";
        }
    }
    const getUserAudioSrc = () => {
        if (grading_result.status === 'fulfilled') {
            if ('user_audio_url' in grading_result.data.data) {
                return grading_result.data.data['user_audio_url']
            } else {
                return grading_result.data.data['user_audio'];
            }
        } else {
            return "";
        }
    }
    const getSpeakingQuestionAnswerList = () => {
        if (grading_result.status === 'fulfilled') {
            if ('speaking_question_answer_list' in grading_result.data.data) {
                return grading_result.data.data['speaking_question_answer_list']
            } else {
                return []
            }
        } else {
            return [];
        }
    }
    const getExampleAudioSrc = () => {
        if (grading_result.status === 'fulfilled') {
            if ('example_audio_url' in grading_result.data.data) {
                return grading_result.data.data['example_audio_url']
            } else {
                return grading_result.data.data['example_audio'];
            }
        } else {
            return "";
        }
    }
    const getTime = () => {
        var date = new Date(grading['created_at'] * 1000);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var date = date.getDate();
        return year + "-" + month + "-" + date;
    }

    const getType = () => {
        if (grading_result.status === 'fulfilled') {
            const gen_type = grading_result.data.data['gen_type'].split("_");
            let part1 = "";
            let part2 = "";
            if (gen_type[0] == 'speaking') {
                part1 = t("speaking");
            } else {
                part1 = t("writing");
            }

            if (gen_type[1] == "generation") {
                part2 = t("corpus_accumulation");
            } else {
                part2 = gen_type[1].replace("task", t("task"));
            }

            return part1 + part2;
        } else {
            return "";
        }
    }

    const getGenType = () => {
        if (grading_result.status === 'fulfilled') {
            return grading_result.data.data['gen_type'];
        } else {
            return "";
        }
    }

    const isFieldVisible = (fieldName) => {
        return fieldName in grading && grading[fieldName];
    }

    const getBox = (fieldName) => {
        let title = fieldName;
        if (fieldName == 'content') {
            title = t("your_answer")
        } else if (fieldName == 'topic') {
            title = t('question_text')
        } else if (fieldName == 'target_score') {
            title = t('target_score')
        } else if (fieldName == 'outline') {
            title = t('outline')
        } else if (fieldName == 'listening') {
            title = t('listening_text')
        } else if (fieldName == 'reading') {
            title = t('reading_text')
        } else if (fieldName == 'conversations') {
            title = t('writing_discussion')
        }

        return (
            <Box display={isFieldVisible(fieldName) ? "block" : "none"}>
                <SectionCard p="0px">
                    <SectionCardContent>
                        <SectionCardHeading>{title}</SectionCardHeading>
                        {fieldName === 'conversations' ? (
                                <ConversationTable dataType={'grading'}/>
                            ) :
                            <SectionCardText>
                                {getField(fieldName)}
                            </SectionCardText>}
                    </SectionCardContent>
                </SectionCard>
            </Box>

        );
    };

    const getResult = (fieldName) => {
        return <>
            <Box mt={10} p={5} bg='white'
                 boxShadow="0 2px 12px 0 rgb(0 0 0 / 16%)"
                 borderRadius={20}
                 display={isFieldVisible(fieldName) ? "block" : "none"}>
                <Center>
                    <Box m="20px" overflowX="auto">
                        <ReactMarkdown
                            children={getField(fieldName)} remarkPlugins={[gfm]}
                            rehypePlugins={[rehypeRaw]} className="ll-markdown"
                            components={ChakraMarkdownComponents}
                        />
                    </Box>
                </Center>
            </Box>
        </>;
    };


    return (
        (grading_result.status === 'fulfilled' && grading_result.data.data['result'] && isJsonData(grading_result.data.data['result'])) ?
            <ViewAppResultPanel gid={gid} gradingDat={grading_result}></ViewAppResultPanel> :
            <>

                <Container maxW='1000px' display={grading_result.status === 'pending' ? "block" : "none"}>
                    <Center>
                        <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='blue.500'
                            size='lg'
                        />
                    </Center>
                </Container>
                <Container maxW='1000px' mt="70px"
                           display={grading_result.status === 'rejected' ? "block" : "none"}>
                    <Center>
                        <Text p="5px" ml="10px" mb="0" fontWeight="500" fontSize="xl">{t("share_not_found")}</Text>
                    </Center>
                </Container>
                <Container maxW='1000px' display={grading_result.status === 'fulfilled' ? "block" : "none"}>
                    <Flex flexDirection={"column"} gap='15px'>
                        <Flex spacing="10">
                            <Center>
                                <Text p="5px" ml="10px" mb="0" fontWeight="500">{getTime()}</Text>
                            </Center>
                            <Center>
                                <Text p="5px" ml="10px" mb="0" fontWeight="500">{getType()}</Text>
                            </Center>

                            <Box flex="1"></Box>
                            <ShareResultButton gid={gid}
                                               visible={grading.is_admin_privileged || (grading_result.status === 'fulfilled' && grading_result.data.data['is_owner'])}></ShareResultButton>
                            <Box color={'green.400'} px={2} py={2} width="10px">
                                <Icon as={CheckIcon} display={linkCopied ? "block" : "none"}/>
                            </Box>
                        </Flex>
                        <Flex ml="20px">
                            <Center ml="10px">
                                <Badge
                                    variant='outline' colorScheme='blue'
                                    display={grading.tpo ? "block" : "none"}
                                >
                                    {getGenType() == 'writing_task3' ? t('question_space') : 'TPO '}{grading.tpo}
                                </Badge>
                            </Center>
                            <Center ml="10px">
                                <Badge
                                    variant='outline' colorScheme='green'
                                    display={grading.estimated_score != null ? "block" : "none"}
                                >
                                    {t("score")}：{grading.estimated_score}
                                </Badge>
                            </Center>
                            <Center ml="10px">
                                <Badge
                                    variant='outline' colorScheme='red'
                                    display={grading.is_admin_privileged ? "block" : "none"}
                                >
                                    Admin Privileged
                                </Badge>
                            </Center>
                        </Flex>
                        {getBox('topic')}
                        {getBox('target_score')}
                        {getBox('outline')}
                        {getBox('listening')}
                        {getBox('reading')}
                        {getBox('conversations')}
                        <Box
                            display={isFieldVisible("user_audio_url") || isFieldVisible("user_audio") ? "block" : "none"}><SectionCard>
                            <SectionCardContent>
                                <SectionCardHeading>{t("your_answer")}</SectionCardHeading>
                                <SectionCardText>
                                    <ReactAudioPlayer
                                        src={getUserAudioSrc()}
                                        controls={true}
                                    />
                                </SectionCardText>
                            </SectionCardContent>
                        </SectionCard></Box>
                        <Box
                            display={isFieldVisible("speaking_question_answer_list") ? "block" : "none"}><SectionCard>
                            <SectionCardContent>
                                <SectionCardHeading>{t("your_answers")}</SectionCardHeading>
                                <SectionCardText>
                                    <IeltsSpeakingQuestionAnswerList
                                        data={getSpeakingQuestionAnswerList()}></IeltsSpeakingQuestionAnswerList>
                                </SectionCardText>
                            </SectionCardContent>
                        </SectionCard></Box>
                        {getBox('content')}
                        {getResult('result')}
                        <Box
                            display={isFieldVisible("example_audio_url") || isFieldVisible("example_audio") ? "block" : "none"}><SectionCard>
                            <SectionCardContent>
                                <SectionCardHeading>{t("example_recording")}</SectionCardHeading>
                                <SectionCardText>
                                    <ReactAudioPlayer
                                        src={getExampleAudioSrc()}
                                        controls={true}
                                    />
                                </SectionCardText>
                            </SectionCardContent>
                        </SectionCard></Box>
                        <Box display={isOwner ? "block" : "none"}>
                            <SectionCard>
                                <SectionCardContent>
                                    <SectionCardHeading>{t('your_santisfication_rate')}</SectionCardHeading>
                                    <SectionCardText>
                                        <ResponseFeedBack gid={gid}></ResponseFeedBack>
                                    </SectionCardText>
                                </SectionCardContent>
                            </SectionCard>
                        </Box>
                    </Flex>
                </Container>

            </>
    );
}