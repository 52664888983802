import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Center, Checkbox,
    Divider, Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    Input,
    Text,
    useColorModeValue,
    useDisclosure, useToast,
} from "@chakra-ui/react";
import CardHeader from "./Card/CardHeader";
import CardBody from "./Card/CardBody";
import Card from "./Card/Card";
import authHeader from "../services/auth-header";
import axios from "axios";
import { selectUserDetails, updateUserDetails } from "../redux/adminSlice";
import { PaginatedTable } from "./PaginatedTable";
import { useDispatch, useSelector } from "react-redux";
import Figure from "react-bootstrap/Figure";
import logoPng from "../assets/logo_long.png";
import { ViewResultPanel } from "./ViewResultPanel";
import { useAdminTopUpMutation } from "../services/cmApi";
import { HistoryRow } from "./Tables/HistoryRow";
import { AdminCreditHistoryRow } from "./Tables/AdminCreditHistoryRow";

export function AdminSearchUserPanel(props) {
    const textColor = useColorModeValue("gray.700", "white");

    const [input, setInput] = useState("");
    const [users, setUsers] = useState([]);
    const [results, setResults] = useState([]);

    const userDetails = useSelector(selectUserDetails);
    const dispatch = useDispatch();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;
    const [topUpPoints, setTopUpPoints] = useState("");
    const [topUpReason, setTopUpReason] = useState("");
    const [topUpValidDays, setTopUpValidDays] = useState("");
    const [topUpIsLimitedCredits, setTopUpIsLimitedCredits] = useState("");
    const [topUpRequest, isTopUpError] = useAdminTopUpMutation();
    const toast = useToast()

    // Fetches users from the backend using the search API
    const fetchUsers = async () => {
        try {
            let headers = authHeader();

            const response = await axios.get(API_URL + `/api/search_users`, {
                headers: headers,
                params: {
                    query: input,
                    limit: 20 // You can adjust this limit as needed
                }
            });
            setUsers(response.data);
        } catch (err) {
            console.error(err);
        }
    };

    const executeTopUp = (userId) => {
        console.log("execute top up", topUpPoints)

        let formData = {
            "user_id": userId,
            "points": Number(topUpPoints),
            "reason": topUpReason,
            "valid_days": topUpValidDays,
            "is_limited_credits": topUpIsLimitedCredits,
        };

        topUpRequest(formData)
            .then(function (response) {
                toast({
                    title: JSON.stringify(response),
                    status: "success",
                    duration: 10000,
                    isClosable: true,
                });
            }).catch(function (error) {
                toast({
                    title: JSON.stringify(error),
                    status: "error",
                    duration: 10000,
                    isClosable: true,
                });
            });
    };

    useEffect(() => {
        fetchUsers();
    }, [input]);

    useEffect(() => {
        // Filter users based on search input
        if (input) {
            const filteredResults = users.filter(user =>
                user.username.toLowerCase().includes(input.toLowerCase()) ||
                user.email.toLowerCase().includes(input.toLowerCase()) ||
                user.uid.toLowerCase().includes(input.toLowerCase()) ||
                user.phone.includes(input) ||
                String(user.id).includes(input)
            );
            setResults(filteredResults);
        } else {
            setResults([]);
        }
    }, [input, users]); // Rerun effect whenever input or users changes

    const handleChange = (e) => {
        setInput(e.target.value);
    };


    return (
        <Card
            overflowX={{ sm: "scroll", xl: "hidden" }}
        >
            <CardHeader p="6px 0px 22px 0px">
                <Flex direction="column">
                    <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
                        Search User
                    </Text>
                </Flex>
            </CardHeader>
            <CardBody>
                <Flex flexDirection="column" >
                    <Box>
                        <Input
                            type="text"
                            value={input}
                            onChange={handleChange}
                            placeholder="Search users..."
                        />
                        {results.length > 0 && (
                            <ul>
                                {results.map((item) => (
                                    <li key={item.id}>
                                        id:{item.id} {item.email} {item.uid}
                                        <Button
                                            ml="10px"
                                            bg={'blue.400'} color={'white'}
                                            onClick={async () => {
                                                let headers = authHeader();
                                                const response = await axios.get(API_URL + `/api/admin_get_user?uid=` + item.uid, { headers: headers });
                                                dispatch(updateUserDetails(response.data))
                                            }}
                                        >
                                            Load
                                        </Button>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </Box>
                    <Box
                        mt={10} p={5} bg='gray.200' borderRadius={20}
                        display={userDetails.id ? "block" : "none"}
                    >
                        <Center>
                            <Button
                                ml="10px"
                                bg={'blue.400'} color={'white'}
                                onClick={() => {
                                    dispatch(updateUserDetails({}))
                                }}
                            >
                                Clear
                            </Button>
                        </Center>
                        <Text>
                            {"id: "}{userDetails.id}
                        </Text>
                        <Text>
                            {"Email: "}{userDetails.email}
                        </Text>
                        <Text>
                            {"uid: "}{userDetails.uid}
                        </Text>
                        <Box m="30px"></Box>
                        <Flex>
                            <Input type="text" value={topUpPoints} backgroundColor="white"
                                placeholder='Top-up points'
                                maxWidth="200px"
                                onChange={(evt) => setTopUpPoints(evt.target.value)} />
                            <Box mx="10px"></Box>
                            <Input type="text" value={topUpReason} backgroundColor="white"
                                placeholder='Reason'
                                onChange={(evt) => setTopUpReason(evt.target.value)} />
                            <Box mx="10px"></Box>
                            <Input type="text" value={topUpValidDays} backgroundColor="white"
                                placeholder='Valid Days'
                                onChange={(evt) => setTopUpValidDays(evt.target.value)} />
                            <Box mx="10px"></Box>
                            <Checkbox
                                bgColor="white"
                                px="10px"
                                mr="10px"
                                rounded="5px"
                                isChecked={topUpIsLimitedCredits}
                                onChange={(e) => setTopUpIsLimitedCredits(e.target.checked)}
                            >
                                <Text minWidth="80px">
                                    Will expire
                                </Text>
                            </Checkbox>
                            <Button onClick={() => executeTopUp(userDetails.id)} bg={'blue.400'} color={'white'} px="30px">Top up</Button>
                        </Flex>

                        <Flex>
                            <PaginatedTable
                                data={userDetails.gpt_responses || []}
                                onRowSelected={onOpen}
                                rowComponent={HistoryRow}
                                filterProduct={false}
                            ></PaginatedTable>
                            <Box mx="5px"></Box>
                            <PaginatedTable
                                data={userDetails.credit_history || []}
                                onRowSelected={onOpen}
                                rowComponent={AdminCreditHistoryRow}
                                pageSize={10}
                                filterProduct={false}
                            ></PaginatedTable>
                        </Flex>
                    </Box>
                    <Drawer onClose={onClose} isOpen={isOpen} size="full" placement="bottom">
                        <DrawerOverlay />
                        <DrawerContent bgColor="gray.100">
                            <DrawerCloseButton mr="20px" mt="20px" color="white" />
                            <DrawerHeader bg="blue.800">
                                <Box bg="blue.800">
                                    <Figure.Image width={200} height={20} src={logoPng} style={{ marginBottom: "0px" }} />
                                </Box>
                            </DrawerHeader>
                            <DrawerBody bg="white">
                                <ViewResultPanel></ViewResultPanel>
                            </DrawerBody>
                        </DrawerContent>
                    </Drawer>
                </Flex>
            </CardBody>
        </Card>
    );
}